
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from "vuex";

@Component({
  components: {
  },
  computed: {
    ...mapGetters(["appShow"])
  }
})
export default class Code extends Vue {


  created(){

  }

  appHide(){
    const _this:any = this;
    _this.$store.commit('SET_APPSHOW',false)
  }

}
