import Vue from 'vue';
import Router, {NavigationGuardNext, RawLocation, Route} from 'vue-router';
import PageRouter from "@/router/PageRouter";
import base from '@/utils/base'

Vue.use(Router);
const originalPush:any = Router.prototype.push
Router.prototype.push = function (location:RawLocation){
  return originalPush.call(this,location).catch((err:any) => err)
}
const routes = [
  ...PageRouter
];

const router: any = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to:Route, from:Route, savedPosition:any) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
});



router.beforeEach((to:any,from:Route,next:NavigationGuardNext<Vue>)=>{
  const token = localStorage.getItem('_TOKEN')
  if(base.isNull(from.name) && to.meta.isToken == true && base.isNull(token)){
    localStorage.clear()
    let path = ""
    if(base.isNull(to.meta.isRouter)){
      path = base.routeLinkSplice("/Login",'')
    }
    else{
      path = base.routeLinkSplice("/Login",to.meta.isRouter)
    }
    base.goRouter(path)
    return
  }

  if(to.meta.isToken == true && base.isNull(token)){
    base.goRouter(to.fullPath)
    return
  }
  next()

})

export default router;
