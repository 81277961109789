import { DemoInterface } from '@/interface/store/demo'
const demo = {
    state: {
        data:'123123',
        type:0
    },
    actions: {

    },
    mutations: {
        SET_DATA(state:DemoInterface, data:string) {
            state.data = data;
        },
        SET_TYPE(state:DemoInterface, data:number) {
            state.type = data;
        },
        REMOVE_DATA(state:DemoInterface, data:string) {
            state.data = '';
        },
    }
}
export default demo
