/*
 * @Descripttion: 天道通勤
 * @version: 1.0.1
 * @Author: 汤玉鹏
 * @Date: 2021-12-20 11:39:08
 * @LastEditors: typ
 * @LastEditTime: 2022-11-14 14:37:20
 */
import {ShoppingCart} from "@/interface/store/shoppingCart";
import {GetMyCartsApi, GetMyCartsNumAndPriceApi} from "@/network/modules/common";
const shoppingCart = {
    state: {
        list:[],
        listTotal:0,
        listNum:{}
    },
    actions: {
        async GetMyCartsFn({},_self?:any){
            const _this:any = this;
            const res = await GetMyCartsApi({
                commoditySeries:0
            })
            const { code , data, msg } = res
            console.log(data);
            let dataInfo=data.map((val:any,index:any)=>{
                return {
                    ...val,
                    cartPriceOld:val.cartPrice,
                    cartPrice:val.isDeposit==0?val.cartPrice:(val.cartPrice*val.depositRatio),
                }
            })
            console.log(dataInfo);
            if(code != 200 ){
                _self.$message.error(msg);
                return
            }
            data.map((item:any) => item.checked = false)
            _this.commit('SET_LIST',JSON.stringify(dataInfo))
            // _self.globalCallback('userInfo')
        },
        async GetMyCartsNumAndPriceFn({},_self?:any){
            const _this:any = this;
            const res = await GetMyCartsNumAndPriceApi()
            const { code , data, msg } = res
            console.log('----------------------');
            if(code != 200 ){
                _self.$message.error(msg);
                return
            }
            _this.commit('SET_LISTNUM',JSON.stringify(data))
            // _self.globalCallback('userInfo')
        },
    },
    mutations: {
        SET_LIST(state:ShoppingCart, data:string) {
            state.list = JSON.parse(data)
            let list = JSON.parse(data);
            console.log(list);
            let num = 0;
            list.map((item:any) => {
                if(
                  item.commoditySeries != 2 && item.invalidStatus == 0 ||
                  item.commoditySeries == 2 && item.disposalStatus != 3 && item.invalidStatus == 0
                ){
                    num += parseInt(item.cartNum)
                }
            })
            console.log('%c [num]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', num)
            state.listTotal = num
        },
        UNSHIFT_LIST(state:ShoppingCart, data:string) {
            state.list.unshift(JSON.parse(data))
            let num = 0;
            state.list.map((item:any) => {
                if(
                  item.commoditySeries != 2 && item.invalidStatus == 0 ||
                  item.commoditySeries == 2 && item.disposalStatus != 3 && item.invalidStatus == 0
                ){
                    num += parseInt(item.cartNum)
                }
            })
            console.log('%c [num]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', num)
            state.listTotal = num
        },
        SET_LISTNUM(state:ShoppingCart, data:string) {
            state.listNum = JSON.parse(data)
        },
        REMOVE_LISTITEM(state:ShoppingCart, id:string) {
            let index = state.list.findIndex((item:any) => item.cartId == id)
            state.list.splice(index,1)
            let num = 0;
            state.list.map((item:any) => {
                if(
                  item.commoditySeries != 2 && item.invalidStatus == 0 ||
                  item.commoditySeries == 2 && item.disposalStatus != 3 && item.invalidStatus == 0
                ){
                    num += parseInt(item.cartNum)
                }
            })
            console.log('%c [num]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', num)
            state.listTotal = num
        },
        REMOVE_LIST(state:ShoppingCart) {
            state.list = []
            state.listTotal = 0
        },
    }
}
export default shoppingCart
