var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-button',{style:({
      width:_vm.iWidth,
      height:`${_vm.iHeight}px`,
      'line-height':`${_vm.iHeight}px`,
      'border-radius':`${_vm.iRadius}px`,
      'font-size':`${_vm.fontSize}px`
    }),attrs:{"type":_vm.iType,"disabled":_vm.iDisabled,"loading":_vm.iLoading,"icon":_vm.icon},on:{"click":_vm.callback}},[_vm._v(_vm._s(_vm.text))])
}
var staticRenderFns = []

export { render, staticRenderFns }