
import {Component, Vue, Prop, Provide, Watch} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class Dialog extends Vue {
  @Prop({ type: String, default: '' }) readonly title ?: string;
  @Prop({ type: String, default: '确定' }) readonly confirmText ?: string;
  @Prop({ type: String, default: '确定' }) readonly confirmTooText ?: string;
  @Prop({ type: String, default: '取消' }) readonly cancelText ?: string;
  @Prop({ type: String, default: '100%' }) readonly iWdith ?: string;
  @Prop({ type: Boolean, default: false }) readonly isCancel ?: boolean;
  @Prop({ type: Boolean, default: false }) readonly isConfirmToo ?: boolean;
  @Prop({ type: Boolean, default: true }) readonly isBtn ?: boolean;
  @Prop({ type: Boolean, default: false }) readonly loading ?: boolean;
  @Prop({ type: Boolean, default: false }) readonly tooLoading ?: boolean;
  @Prop({ type: Boolean, default: false }) readonly show !: boolean;
  @Prop({ type: Boolean, default: true }) readonly isClose !: boolean;

  @Provide() dialogVisible: boolean = false
  @Watch('show',{immediate:true, deep:false})
  private iValChange():void {
    const _this:any = this
    _this.dialogVisible = _this.show
  }


  handleClose(done:any) {
    const _this:any = this;
    _this.$emit('dialogClose')
    // _this.$confirm('确认关闭？')
    //   .then(() => {
    //     _this.$emit('dialogClose')
    //     done();
    //   })
    //   .catch(() => {});
  }


}
