

import { Component, Vue, Prop, Provide, Watch } from 'vue-property-decorator';
@Component({
  components: {
  },
})

export default class Input extends Vue {
  @Prop({ type: String, default: '' }) readonly value ?: string;
  @Prop({ type: String, default: '' }) readonly iType ?: string;
  @Prop({ type: Boolean, default: false }) readonly showPassword ?: boolean;
  // white none black
  @Prop({ type: String, default: 'white' }) readonly bg ?: string;
  @Prop({ type: String, default: '46' }) readonly iHeight ?: string;
  @Prop({ type: String, default: '请输入' }) readonly pla ?: string;

  @Prop({ type: String, default: '' }) readonly icon ?: string;
  @Prop({ type: String, default: '#929292' }) readonly iconColor ?: string;
  @Prop({ type: String, default: '18' }) readonly iconSize ?: string;

  @Prop({ type: String, default: 'prefix' }) readonly iconSlot ?: string;
  @Prop({ type: Boolean, default: false }) readonly disabled ?: boolean;
  @Prop({ type: Boolean, default: false }) readonly autofocus ?: boolean;
  @Prop({ type: Number, default: 99999 }) readonly maxlength ?: number;
  @Prop({ type: String, default: '' }) readonly label ?: string;
  @Prop({ type: String, default: '0' }) readonly labelWidth ?: string;
  @Prop({ type: String, default: '0' }) readonly labelHeight ?: string;
  //top left
  @Prop({ type: String, default: 'left' }) readonly labelPosition ?: string;
  //err success
  @Prop({ type: String, default: 'err' }) readonly msgType ?: string;
  @Prop({ type: String, default: '#FFCA85' }) readonly msgColor ?: string;
  @Prop({ type: String, default: '' }) readonly msg ?: string;
  @Prop({ type: String, default: '20' }) readonly pHeight ?: string;
  @Prop({ type: Boolean, default: false }) readonly showWordLimit ?: boolean;


  @Provide() defaultValue: string = ""
  @Watch('autofocus',{immediate:true, deep:true})
    private autoFocus(newVal: boolean, oldVal: boolean):void {
      const _this:any = this
      if(newVal){
        _this.$nextTick(()=>{ //自动获取焦点 element组件autofocus失效
            _this.$refs['atlas-name-input'].$refs.input.focus()
        })
      }
    }

  @Watch('value',{immediate:true, deep:false})
  private iValChange():void {
    const _this:any = this
    _this.defaultValue = _this.value
  }

  callback(val:string){
    const data = val
    const _this:any = this;
    _this.$emit("input", data);
  }

  keyupCallback(){
    this.$emit("keyupCallback");
  }

  focusCallback(){
    this.$emit("focusCallback");
  }

  blurCallback(){
    this.$emit("blurCallback");
  }

}
