import { Address } from '@/interface/store/address'
import {GetMyAddressApi} from "@/network/modules/mine";
const address = {
    state: {
        list:[]
    },
    actions: {
        async GetMyAddressFn({},_self?:any){
            const _this:any = this;
            const res = await GetMyAddressApi()
            const { code , data, msg } = res
            if(code != 200 ){
                _self.$message.error(msg);
                return
            }
            const sData = JSON.stringify(data)
            _this.commit('SET_ADDRESS_LIST',sData)
            // _self.globalCallback('userInfo')
        },

    },
    mutations: {
        SET_ADDRESS_LIST(state:Address, data:string){
            let list = JSON.parse(data)
            let isDefault = list.filter((item:any) => item.defaultStatus == 1)
            let arr = list.filter((item:any) => item.defaultStatus == 0)
            state.list = [...isDefault,...arr]
        },
        SET_ADDRESS_LIST_PUSH(state:Address, data:string){
            let item = JSON.parse(data)
            if(item.defaultStatus == 1){
                state.list.unshift(item)
            }
            else{
                state.list.push(item)
            }
        },
        REMOVE_ADDRESS_LIST_ITEM(state:Address,data:number){
            state.list.splice(data,1)
        },
        REMOVE_ADDRESS_LIST(state:Address){
            state.list = []
        }
    }
}
export default address
