import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//element-ui
import {
  Message,
  Loading,
  MessageBox,
  Container,
  Form,
  FormItem,
  Input,
  Button,
  Dialog,
  Image,
  Upload,
  DatePicker,
  Tooltip,
  Cascader,
  Carousel,
  CarouselItem,
  Link,
  Progress,
  Pagination,
  Rate,
  Popover
} from 'element-ui';
Vue.component(Container.name, Container);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Input.name, Input);
Vue.component(Button.name, Button);
Vue.component(Dialog.name, Dialog);
Vue.component(Image.name, Image);
Vue.component(Upload.name, Upload);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Cascader.name, Cascader);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Link.name, Link);
Vue.component(Progress.name, Progress);
Vue.component(Pagination.name, Pagination);
Vue.component(Rate.name, Rate);
Vue.component(Popover.name, Popover);
Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/element-ui.scss'

// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)

//路由
import IndexConfig from "@/router/modules/IndexConfig";
import DemoConfig from "@/router/modules/DemoConfig";
import LoginConfig from "@/router/modules/LoginConfig";
import MineConfig from "@/router/modules/MineConfig";
import CommonConfig from "@/router/modules/CommonConfig";
Vue.prototype.$DemoConfig = DemoConfig;
Vue.prototype.$LoginConfig = LoginConfig;
Vue.prototype.$IndexConfig = IndexConfig;
Vue.prototype.$MineConfig = MineConfig;
Vue.prototype.$CommonConfig = CommonConfig;

//base64
import { encode, decode } from 'js-base64';
Vue.prototype.$encode = encode;
Vue.prototype.$decode = decode;

import { computedMapDict, filtersMapDict } from '@/utils/dict'
Vue.mixin({
  computed: {
    ...computedMapDict()
  },
  filters: {
    ...filtersMapDict()
  }
})

import iInput from "@/components/Input.vue"
Vue.component("iInput", iInput);

import iDialog from '@/components/Dialog.vue'
Vue.component("iDialog", iDialog);

import iButton from '@/components/Button.vue'
Vue.component("iButton", iButton);

import iRadio from '@/components/Radio.vue'
Vue.component("iRadio", iRadio);

import LoginDialog from '@/components/LoginDialog.vue'
Vue.component("LoginDialog", LoginDialog);

import iNull from '@/components/Null.vue'
Vue.component("iNull", iNull);

import NotFound from '@/components/NotFound.vue'
Vue.component("NotFound", NotFound);

//动画
import animate from 'animate.css'
Vue.use(animate)

//全局回调
import globalCallback from '@/utils/globalCallback'
Vue.use(globalCallback)


//加密
import {adminEncryptBy, adminDecryptBy, encryptBy, decryptBy, getDecryptBy, setEncryptBy } from "@/utils/encryption";
Vue.prototype.$adminEncryptBy = adminEncryptBy;
Vue.prototype.$adminDecryptBy = adminDecryptBy;
Vue.prototype.$encryptBy = encryptBy;
Vue.prototype.$decryptBy = decryptBy;
Vue.prototype.$getDecryptBy = getDecryptBy;
Vue.prototype.$setEncryptBy = setEncryptBy;


Vue.directive('preventReClick', {
  inserted: function (el:HTMLElementPlus, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        let a = setTimeout(() => {
          el.disabled = false
          clearTimeout(a);
        }, binding.value || 1000)
      }
    })
  }
});

//公共js
import base from '@/utils/base'
Vue.prototype.$base = base

//公共导出数据
import {uploadUrl, headers, uploadFilesUrl} from "@/utils//const";
import { HTMLElementPlus } from './interface/common';
Vue.prototype.$uploadUrl = uploadUrl
Vue.prototype.$uploadFilesUrl = uploadFilesUrl
Vue.prototype.$headers = headers

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
