
import { Component, Provide, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class Footer extends Vue {
  @Provide() menuList: Array<any> = [
    {
      title: "购物指南",
      titleEn: "Shopping Guide",
      list: [
        {
          name: "定单跟踪",
          en: "Order Tracking",
          link: "order",
          img: "",
          icon: "",
          flag: false,
          type: 0,
          isToken: true,
        },
        {
          name: "账户管理",
          en: "Account Management",
          link: "account",
          img: "",
          icon: "",
          flag: false,
          type: 0,
          isToken: true,
        },
        {
          name: "帮助中心",
          en: "Help Center",
          link: "help",
          img: "",
          icon: "",
          flag: false,
          type: 0,
          isToken: false,
        },
        {
          name: "反馈意见",
          en: "Feedback",
          link: "opinion",
          img: "",
          icon: "",
          flag: false,
          type: 0,
          isToken: false,
        },
      ],
    },
    {
      title: "服务支持",
      titleEn: "Service Support",
      list: [
        {
          name: "售后服务",
          en: "After Sale",
          link: "",
          img: "",
          icon: "",
          flag: false,
          type: 5,
          isToken: true,
        },
        {
          name: "高级定制",
          en: "Custom Made",
          link: "guide",
          img: "",
          icon: "",
          flag: false,
          type: 4,
          isToken: false,
        },
        {
          name: "手机App",
          en: "Mobile App",
          link: "",
          img: "",
          icon: "",
          flag: false,
          type: 3,
        },
        {
          name: "隐私保护",
          en: "Privacy Protection",
          link: "privacy",
          img: "",
          icon: "",
          flag: false,
          type: 0,
          isToken: false,
        },
      ],
    },
    {
      title: "关于我们",
      titleEn: "About Us",
      list: [
        {
          name: "官方网站",
          en: "Official Website",
          link: "http://zqkj.sanxiaxiu.com/html/pc/index.html",
          img: "",
          icon: "",
          flag: false,
          type: 1,
        },
        {
          name: "关注我们",
          en: "",
          link: "",
          img: require("@/assets/image/common/qrcode.png"),
          icon: require("@/assets/image/common/wechat.png"),
          flag: false,
          type: 1,
        },
        {
          name: "官方微博",
          en: "",
          link: "",
          img: "",
          icon: require("@/assets/image/common/sina.png"),
          flag: false,
          type: 1,
        },
        {
          name: "联系我们",
          en: "Contact Us",
          link: "http://zqkj.sanxiaxiu.com/html/pc/cooperation.html",
          img: "",
          icon: "",
          flag: false,
          type: 1,
        },
      ],
    },
  ];

  /**
   * @Author HS
   * @Date 2021/10/19 6:11 下午
   * @Description: 页面跳转类型
   * @Params: { number ：iType - true } [跳转类型]
   * @Params: { string ：link - true } [跳转地址]
   * @Params: { boolean ：openLink - false } [是否打开新窗口]
   * @Params: { boolean ：isToken - false } [是否需要登陆]
   * @Return: null
   */
  goRouteType(
    iType: number,
    link: string,
    openLink: boolean,
    isToken: boolean
  ) {
    const _this: any = this;
    console.log(link);
    switch (iType) {
      case 0:
        _this.goMine(link, isToken);
        //     _this.$store.commit('SET_SERVICESHOW', true)
        // _this.$store.commit('SET_UNREAD', 0);
        break;
      case 1:
        _this.goRoute(link, openLink);
        break;
      case 3:
        _this.$store.commit("SET_APPSHOW", true);
        break;
      case 4:
        _this.goIndex(link, isToken);
        break;
      case 5:
        _this.$store.commit("SET_SERVICESHOW", true);
        _this.$store.commit("SET_UNREAD", 0);
        break;
    }
  }

  /**
   * @Author HS
   * @Date 2021/9/3 5:28 下午
   * @Description: 页面跳转
   * @Params: { string ：link - true } [跳转地址]
   * @Params: { boolean ：openLink - false } [是否打开新窗口]
   * @Return: null
   */
  goRoute(link: string, openLink: boolean) {
    const _this: any = this;
    if (_this.$base.isNull(link)) return;
    if (openLink) {
      window.open(link, "_blank");
      return;
    }
    window.open(link);
  }

  /**
   * @Author HS
   * @Date 2021/7/9 3:56 下午
   * @Description: Mine页面跳转
   * @Params: { string ：path - true } [跳转地址]
   * @Params: { boolean ：isToken - false } [是否需要登陆]
   * @Return: null
   */
  goMine(path: string, isToken: boolean) {
    const _this: any = this;
    if (_this.$base.isLogin() && isToken) {
      const link = _this.$base.routeLinkSplice(
        _this.$LoginConfig.login.path,
        path
      );
      _this.$base.goRouter(link);
      return;
    }
    _this.$base.goRouter(_this.$MineConfig[path].path);
  }
  /**
   * @Author HS
   * @Date 2021/7/9 3:56 下午
   * @Description: Index页面跳转
   * @Params: { string ：path - true } [跳转地址]
   * @Params: { boolean ：isToken - false } [是否需要登陆]
   * @Return: null
   */
  goIndex(path: string, isToken: boolean) {
    const _this: any = this;
    if (_this.$base.isLogin() && isToken) {
      const link = _this.$base.routeLinkSplice(
        _this.$LoginConfig.login.path,
        path
      );
      _this.$base.goRouter(link);
      return;
    }
    _this.$base.goRouter(_this.$IndexConfig[path].path);
  }
}
