
import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({
  components: {
  },
})
export default class Null extends Vue {
  @Prop({ type: String, default: '暂无' }) readonly text ?: string;
  @Prop({ type: String, default: 'none' }) readonly bg ?: string;
  @Prop({ type: Number, default: 500 }) readonly iHeidth ?: number;
  @Prop({ type: String, default: "126px" }) readonly imgWidth ?: number;
  @Prop({ type: Number, default: 40 }) readonly paddingTop ?: number;

}
