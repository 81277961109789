
import {Component, Prop, Vue} from 'vue-property-decorator';
@Component({
  components: {

  },
})
export default class LoginDialog extends Vue {
  @Prop({ type: String, default: '490' }) readonly iHeight ?: string;


}
