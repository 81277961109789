
import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from "vuex";

@Component({
  components: {
  },
  computed: {
    ...mapGetters(["token","userInfo","serviceUnread"])
  }
})
export default class WebSocketBox extends Vue {
  @Provide() socket: any = null;
  @Provide() params: {
    action?:number,
    authentication?:string,
    msgContent?:any,
  } = {}
  @Provide() lockReconnect:boolean = false
  @Provide() timeout:number = 10*1000
  @Provide() timeoutObj: any = null
  @Provide() serverTimeoutObj: any = null
  @Provide() timeoutnum: any = null
  @Watch('token',{immediate:true,deep:false})
  private tokenChange():void {
    const _this:any = this;
    if(_this.$base.isNull(_this.token)){
      _this.quit()
      return
    }

  }
  @Watch('userInfo',{immediate:true,deep:false})
  private userInfoChange():void {
    const _this:any = this;
    if(_this.$base.isNull(_this.token)){
      _this.quit()
      return
    }
    if(_this.userInfo.accountId == -1)return;
    _this.init()
  }


  created(){

  }

  /**
   * @Author HS
   * @Date 2021/7/26 5:04 下午
   * @Description: 页面销毁
   * @Params: null
   * @Return: null
  */
  destroyed () {
    const _this:any = this;
    console.log("页面销毁----")
    _this.quit()
  }

  /**
   * @Author HS
   * @Date 2021/7/26 5:02 下午
   * @Description: 初始化
   * @Params: null
   * @Return: null
  */
  init(){
    const _this:any = this;
    if(typeof(WebSocket) === "undefined"){
      alert("您的浏览器不支持socket")
      return
    }
    _this.socket = _this.$store.state.socket || new WebSocket(process.env.VUE_APP_SOCKET_OTC)

    if(_this.$base.isNull(_this.$store.state.socket)){
      _this.$store.commit('SET_WEBSOCKET',{
        socket:_this.socket,
        socketThis:_this,
      });
    }

    // 监听socket连接
    _this.socket.onopen = this.open
    // 监听socket错误信息
    _this.socket.onerror = this.error
    // 监听socket消息
    _this.socket.onmessage = this.getMessage

  }
  /**
   * @Author HS
   * @Date 2021/7/26 5:02 下午
   * @Description: 重新连接
   * @Params: null
   * @Return: null
  */
  reconnect() {
    const _this:any = this;
    if(_this.lockReconnect) return;

    _this.$store.commit("SET_IS_WEBSOCKET", false);
    _this.lockReconnect = true;
    console.log("重新连接----")
    //没连接上会一直重连，设置延迟避免请求过多
    _this.timeoutnum && clearTimeout(_this.timeoutnum);
    _this.timeoutnum = setTimeout(function () {
      //新连接
      _this.init();
      _this.lockReconnect = false;
    },5000);
  }

  /**
   * @Author HS
   * @Date 2021/7/26 5:02 下午
   * @Description: 重置心跳
   * @Params: null
   * @Return: null
  */
  reset(){
    const _this:any = this;
    //清除时间
    clearTimeout(_this.timeoutObj);
    clearTimeout(_this.serverTimeoutObj);
    //重启心跳
    _this.start();
  }

  /**
   * @Author HS
   * @Date 2021/7/26 5:01 下午
   * @Description: 心跳,，会重新连接
   * @Params: null
   * @Return: null
  */
  start(){
    const _this:any = this;
    _this.timeoutObj && clearTimeout(_this.timeoutObj);
    _this.serverTimeoutObj && clearTimeout(_this.serverTimeoutObj);
    _this.timeoutObj = setTimeout(function(){
      if(_this.$base.isNull(localStorage.getItem('_TOKEN'))){
        _this.quit()
        return
      }
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      if (_this.socket.readyState == 1) {//如果连接正常
        let params = {action : 1}
        _this.sendFn(params);
      }
      else{//否则重连
        _this.reconnect();
      }
      _this.serverTimeoutObj = setTimeout(function() {
        //超时关闭
        _this.close();
      }, _this.timeout);

    }, _this.timeout)
  }
  /**
   * @Author HS
   * @Date 2021/7/26 5:00 下午
   * @Description: 开启连接
   * @Params: null
   * @Return: null
  */
  open(){
    const _this:any = this;
    _this.$store.commit("SET_IS_WEBSOCKET", true);
    let params = {action : 0}
    _this.sendFn(params)
    _this.start();
    console.log("开启连接----")
  }

  /**
   * @Author HS
   * @Date 2021/7/26 5:00 下午
   * @Description: 连接错误,，会重新连接
   * @Params: null
   * @Return: null
  */
  error(){
    const _this:any = this;
    _this.$store.commit('SET_WEBSOCKET',{socket:null,socketThis:null});
    _this.reconnect()
    console.log("连接错误----")

  }

  /**
   * @Author HS
   * @Date 2021/7/26 4:59 下午
   * @Description: 获取信息
   * @Params: { any ： msg - true } [后台返回信息]
   * @Return: null
  */
  getMessage(msg:any){
    const _this:any = this;
    let data = JSON.parse(msg.data);
    let params:any = {}
    let {msgContent} = data;

    switch (data.action) {
        case 0:
          params.action = 1001
          _this.sendFn(params)
          break;
      case 1001:
        let {chatMessageUnReadNum} = data.msgContent;
        _this.$store.commit('SET_UNREAD',chatMessageUnReadNum);
        break;
      case 1002:
        if(msgContent.isOpen == 0)return;
        _this.$store.commit('SET_MESSAGE_LIST',msgContent[0].chatList);
        if(msgContent[0].chatList.length >= 20){
          _this.$store.commit('SET_ISHISTORY',true);
        }
        delete msgContent[0].chatList
        _this.$store.commit('SET_MESSAGE',msgContent[0]);
        break;
      case 1003:
        if(msgContent.readingStatus == 1){
          _this.$store.commit('SET_MESSAGE_LIST_PUSH',msgContent)
          return
        }
        let serviceUnread =  _this.serviceUnread
        serviceUnread++
        _this.$store.commit('SET_UNREAD',serviceUnread)
        break;
      case 1005:
        _this.$store.commit('SET_MESSAGE_LIST_UNSHIFT',msgContent)
        if(msgContent.length >= 40){
          _this.$store.commit('SET_ISHISTORY',true);
          return;
        }
        _this.$store.commit('SET_ISHISTORY',false);
        break;
      case 2001:
        console.log(msgContent);
        _this.$store.commit('SET_PAY_DATA',msgContent)
        break;
      case 2002:
        if(msgContent.update){
          _this.$store.dispatch('GetMyCartsFn',_this)
          _this.$store.dispatch('GetMyCartsNumAndPriceFn',_this)
        }
        break;
	  case 2003:
		_this.$store.commit('ORDERSTATUS', msgContent)
break;
    }


    _this.reset();
  }

  /**
   * @Author HS
   * @Date 2021/7/26 4:58 下午
   * @Description: 发送信息
   * @Params: { any ： params - false } [通信内容]
   * @Return: null
  */
  sendFn(params?:any) {
    const _this:any = this;
    _this.params.action = params.action
    _this.params.authentication = _this.$adminEncryptBy(_this.userInfo.accountId+"_0")
    _this.params.msgContent = params.msgContent || ""
    let msg = JSON.stringify(_this.params)
    _this.socket.send(msg)
  }
  /**
   * @Author HS
   * @Date 2021/7/26 4:58 下午
   * @Description: 连接关闭，会重新连接
   * @Params: null
   * @Return: null
  */
  close() {
    const _this:any = this;
    _this.$store.commit('SET_WEBSOCKET',{socket:null,socketThis:null});
    _this.reconnect()
    console.log("连接关闭----")

  }

  /**
   * @Author HS
   * @Date 2021/7/26 4:53 下午
   * @Description: 退出登录
   * @Params: null
   * @Return: null
  */
  quit(){
    const _this:any = this;
    if(_this.socket){
      _this.socket.close();
      _this.socket.onclose = function() {
        console.log("退出登录----")
        _this.socket = null
        // _this.$store.commit('SET_WEBSOCKET',{socket:null,socketThis:null});
        _this.$store.commit('REMOVE_WEBSOCKET');
        clearTimeout(_this.timeoutObj)
        clearTimeout(_this.serverTimeoutObj)
      };
      return
    }
  }

}
