import { UserInfo } from '@/interface/store/user'
import { GetInfoApi } from "@/network/modules/common";
import { LoginOutApi } from "@/network/modules/login";
const user = {
    state: {
        userInfo:{
            accountId:-1,
            accountName:'',
            accountType:-1,
            customerServiceId:-1,
            sex:-1,
            avatar:'',
            birthday:'',
            email:'',
            nickName:'',
            phone:'',
            realName:'',
            hasPassword:false,
            wechatBind:false,
        },
        token:'',
        countNum:0,
        appShow:false
    },
    actions: {
        async GetInfoFn({},_self?:any){
            console.log('--------A--------');
            const _this:any = this;
            const res = await GetInfoApi()
            const { code , data, msg } = res
            if(code != 200 ){
                _self.$message.error(msg);
                return
            }
            const sData = JSON.stringify(data)
            _self.$setEncryptBy(sData,"_USERINFO")
            _this.commit('SET_USETINFO',sData)
            // _self.globalCallback('userInfo')
        },

        async LoginOutFn({},_self?:any){
            const res = await LoginOutApi()
            const { code, msg } = res
            if(code != 200 ){
                _self.$message.error(msg);
                return
            }
            _self.$base.quitLogin()
        },
    },
    mutations: {
        SET_COUNTNUM(state:UserInfo, data:number){
            console.log(data);
            state.countNum = data
            console.log(state.countNum);
        },
        SET_USETINFO(state:UserInfo, data:string) {
            state.userInfo = JSON.parse(data);
        },
        REMOVE_USETINFO(state:UserInfo) {
            let params = {...state.userInfo}
            params.sex =
              params.customerServiceId =
                params.accountType =
                  params.accountId = -1;
            params.birthday =
              params.email =
                params.avatar =
                  params.nickName =
                    params.phone =
                      params.realName =
                        params.accountName = '';
            params.hasPassword =
              params.wechatBind = false
            state.userInfo = params
        },
        SET_TOKEN(state:UserInfo, data:string){
            localStorage.setItem("_TOKEN",data)
            state.token = data
        },
        REMOVE_TOKEN(state:UserInfo){
            state.token = ''
        },
        SET_APPSHOW(state:UserInfo, data:boolean){
            state.appShow = data
        }
    }
}
export default user
