import {Pay} from "@/interface/store/pay";
const pay = {
    state: {
        data:{}
    },
    actions: {

    },
    mutations: {
        SET_PAY_DATA(state:Pay, data:any) {
            state.data = data;
            console.log(state.data);
        },
        REMOVE_PAY_DATA(state:Pay, data:any) {
            state.data = {}
        },
    }
}
export default pay
