
import { Component, Prop, Provide, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

import { VueChatEmoji, emojis } from "vue-chat-emoji";
import { File } from "@/interface/pagas/common";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
require("vue-chat-emoji/dist/vue-chat-emoji.min.css");
@Component({
  components: {
    Emoji: VueChatEmoji,
    ElImageViewer
  },
  computed: {
    ...mapGetters([
      "userInfo",
      "serviceShow",
      "serviceMessage",
      "serviceList",
      "serviceHistory",
      "serviceCustomizeInfo"
    ])
  }
})
export default class Service extends Vue {
  @Provide() dialogVisible: boolean = false;
  @Provide() showLoading: boolean = false;
  @Provide() val: string = "";
  @Provide() tool: Array<{
    id: number;
    icon: string;
  }> = [
    { id: 0, icon: "custom-icon-biaoqing-xue" },
    { id: 1, icon: "custom-icon-tupian" },
    { id: 2, icon: "custom-icon-wenjian" },
    { id: 3, icon: "custom-icon-shipin" }
  ];
  @Provide() emojiShow: boolean = false;
  @Provide() listShow: boolean = false;
  @Provide() listLoading: boolean = false;
  @Provide() list: Array<any> = [];
  @Provide() listTotal: number = 0;
  @Provide() boxScrollHeight: number = 0;
  @Provide() imgExistingNum: number = 0;
  @Provide() historyLoading: boolean = false;
  @Provide() uploadLength: number = 0;
  @Provide() uploadNum: number = 0;

  @Provide() showBig: boolean = false;
  @Provide() showBigImgList: Array<string> = [];
  @Provide() showBigImgListIndex: number = 0;

  @Watch("serviceList", { immediate: false, deep: true })
  private iListChange(newVal: any): void {
    const _this: any = this;
    if (!_this.$base.isNull(_this.serviceCustomizeInfo)) {
      _this.sendMsg(5, _this.serviceCustomizeInfo);
      _this.$store.commit("REMOVE_CUSTOMIZEINFO");
    }
    if (newVal.length <= 0) {
      _this.showLoading = false;
      return;
    }
    _this.listShow = false;
    _this.list = newVal;
    _this.listTotal = newVal.length;
    let num = 0,
      imgNum = 0,
      videoNum = 0;
    _this.list.map((item: any) => {
      if (item.messageType == 0) {
        num++;
        let newImg = new Image();
        newImg.src = item.messageContent;
        newImg.onload = () => {
          imgNum++;
          if (imgNum + videoNum >= _this.imgExistingNum) {
            _this.calculateScrollPosition();
          }
        };
      } else if (item.messageType == 1) {
        num++;
        let video = document.createElement("video");

        video.setAttribute("src", item.messageContent);
        video.addEventListener("canplaythrough", function() {
          videoNum++;
          if (imgNum + videoNum >= _this.imgExistingNum) {
            _this.calculateScrollPosition();
            video.remove();
          }
        });
      } else if (item.messageType == 5) {
        let arr = item.messageContent.split(",");
        item.messageImg = arr[0];
        item.messageName = arr[1];
        item.messageTimer = arr[2];
      }
    });
    _this.imgExistingNum = num;
    if (num <= 0) {
      _this.calculateScrollPosition();
    }
  }

  @Watch("serviceShow", { immediate: false, deep: false })
  private iShowChange(): void {
    const _this: any = this;
    _this.dialogVisible = _this.serviceShow;
    _this.showLoading = true;

    let params: { action: number; msgContent?: any } = {
      action: 1002,
      msgContent: {}
    };
    if (_this.serviceShow) {
      params.msgContent.isOpen = 1;
      _this.$store.state.webSocket.socketThis.sendFn(params);
    } else {
      params.msgContent.isOpen = 0;
      _this.$store.state.webSocket.socketThis.sendFn(params);
    }

    const mo = function(e: any) {
      e.preventDefault();
    };
    if (_this.serviceShow) {
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    } else {
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    }
  }

  created() {
    const _this: any = this;
    // console.log(_this.serviceMessage)
  }

  /**
   * @Author HS
   * @Date 2021/7/28 5:08 下午
   * @Description: 查看历史记录
   * @Params: null
   * @Return: null
   */
  historyFn() {
    const _this: any = this;
    _this.historyLoading = true;
    let { messageId } = _this.list[0];
    let params: { action: number; msgContent: any } = {
      action: 1005,
      msgContent: {
        userId: _this.serviceMessage.userId,
        messageId: messageId
      }
    };
    _this.$store.state.webSocket.socketThis.sendFn(params);
  }

  /**
   * @Author HS
   * @Date 2021/7/26 5:31 下午
   * @Description: 功能选择
   * @Params: { number ： id - true } [id]
   * @Return:
   */
  toolClickFn(id: number) {
    const _this: any = this;
    switch (id) {
      case 0:
        _this.emojiShow = !_this.emojiShow;
        break;
      case 1:
        _this.$refs["image"].$children[0].$refs.input.click();
        break;
      case 2:
        _this.$refs["file"].$children[0].$refs.input.click();
        break;
      case 3:
        _this.$refs["video"].$children[0].$refs.input.click();
        break;
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/27 2:56 下午
   * @Description: 发送信息
   * @Params: { number ： iType - false } [聊天类型:0-图片 1-视频 2-文件 3-文本 5-产品]
   * @Params: { Object ： data - false } [聊天内容]
   * @Return: null
   */
  sendMsg(iType: number = 3, data: any) {
    const _this: any = this;
    // if(iType != 3)_this.listLoading = true
    let params: { action: number; msgContent: any } = {
      action: 1003,
      msgContent: {
        customerServiceId: _this.serviceMessage.userId,
        userId: _this.userInfo.accountId,
        senderType: "0",
        messageContent: "",
        messageType: iType
      }
    };

    let info = {
      customerServiceAvatar: _this.serviceMessage.avatar,
      customerServiceId: _this.serviceMessage.userId,
      customerServiceNickName: _this.serviceMessage.nickName,
      messageContent: "",
      messageId: -1,
      messageTime: new Date().getTime(),
      messageType: iType,
      readingStatus: 1,
      senderType: 0,
      userAvatar: _this.userInfo.avatar,
      userId: _this.userInfo.accountId,
      userNickName: _this.userInfo.nickName
    };

    if (iType == 3) {
      if (_this.$base.isNull(_this.val)) return;
      params.msgContent.messageContent = _this.val;
      info.messageContent = _this.val;
      _this.$store.commit("SET_MESSAGE_LIST_PUSH", info);
      _this.$store.state.webSocket.socketThis.sendFn(params);
      _this.val = "";
      _this.emojiShow = false;
      return;
    }

    params.msgContent.messageContent = data;
    info.messageContent = data;
    _this.$store.commit("SET_MESSAGE_LIST_PUSH", info);
    _this.$store.state.webSocket.socketThis.sendFn(params);
  }

  selectedEmoji(args: any) {
    console.log('args:', args)
    const _this: any = this;
    _this.emojiShow = false;
    _this.val += args.emoji;
    _this.$nextTick(() => {
      _this.$refs.textarea.focus();
    });
  }

  /**
   * @Author HS
   * @Date 2021/7/26 5:50 下午
   * @Description: 图片上传
   * @Params: {object : response - true}[上传返回信息]
   * @Return: null
   */
  imageSuccess(response: File) {
    const _this: any = this;
    _this.uploadNum++;
    _this.sendMsg(0, response.data);
  }
  /**
   * @Author HS
   * @Date 2021/7/28 3:30 下午
   * @Description: 图片上传超出上线
   * @Params: null
   * @Return: null
   */
  imageExceed() {
    const _this: any = this;
    _this.$message.error("一次最多发送5张图片");
  }

  /**
   * @Author HS
   * @Date 2021/10/20 4:20 下午
   * @Description: 获取上传个数
   * @Params: null
   * @Return: null
   */
  imageChange(files: any, fileList: any) {
    const _this: any = this;
    let upload_img = document.getElementsByClassName("upload-file");
    if (upload_img && upload_img.length > 0) {
      let upload = upload_img[0].getElementsByTagName("input");
      if (
        upload &&
        upload.length > 0 &&
        upload[0].files &&
        upload[0].files.length > 0
      ) {
        _this.uploadLength = upload[0].files.length;
      }
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/28 3:31 下午
   * @Description: 文件上传
   * @Params: {object : response - true}[上传返回信息]
   * @Return: null
   */
  fileSuccess(response: File) {
    const _this: any = this;
    _this.sendMsg(2, response.data);
  }
  /**
   * @Author HS
   * @Date 2021/7/28 3:31 下午
   * @Description: 视频上传
   * @Params: {object : response - true}[上传返回信息]
   * @Return: null
   */
  videoSuccess(response: File) {
    const _this: any = this;
    _this.sendMsg(1, response.data);
  }

  /**
   * @Author HS
   * @Date 2021/8/24 11:21 上午
   * @Description: 图片上传前
   * @Params: null
   * @Return: null
   */
  beforeUpload() {
    const _this: any = this;
    _this.listLoading = true;
    _this.uploadNum = 0;
  }

  /**
   * @Author HS
   * @Date 2021/7/28 10:58 上午
   * @Description: 关闭弹窗
   * @Params: null
   * @Return: null
   */
  serviceHideFn() {
    const _this: any = this;
    if (_this.emojiShow) _this.emojiShow = false;
    _this.$emit("serviceHideCallback");
  }

  /**
   * @Author HS
   * @Date 2021/7/28 10:58 上午
   * @Description: 阻止事件冒泡
   * @Params: null
   * @Return: null
   */
  serviceBoxClick() {
    const _this: any = this;
    // if(_this.emojiShow)_this.emojiShow = false
  }
  /**
   * @Author HS
   * @Date 2021/7/28 10:58 上午
   * @Description: 关闭表情包弹窗
   * @Params: null
   * @Return: null
   */
  emojiHide() {
    const _this: any = this;
    if (_this.emojiShow) _this.emojiShow = false;
  }

  /**
   * @Author HS
   * @Date 2021/7/29 11:19 上午
   * @Description: 图片放大
   * @Params: { number ： index - true } [下标]
   * @Return: null
   */
  imgClick(index: number) {
    const _this: any = this;
    _this.showBigImgList = [];
    let arr = _this.list;
    let newArr: Array<any> = [];
    arr.map((item: any, indexToo: number) => {
      if (item.messageType == 0) {
        _this.showBigImgList.push(item.messageContent);
        let data = JSON.parse(JSON.stringify(item));
        if (index == indexToo) {
          data.isActive = true;
          newArr.push(data);
          return;
        }
        data.isActive = false;
        newArr.push(item);
      }
      if (item.messageType == 5) {
        _this.showBigImgList.push(item.messageImg);
        let data = JSON.parse(JSON.stringify(item));
        if (index == indexToo) {
          data.isActive = true;
          newArr.push(data);
          return;
        }
        data.isActive = false;
        newArr.push(item);
      }
    });
    newArr.map((item, index) => {
      if (item.isActive) {
        _this.showBigImgListIndex = index;
      }
    });

    _this.showBig = true;
  }

  /**
   * @Author HS
   * @Date 2021/7/28 3:31 下午
   * @Description: 计算滚动条高度
   * @Params: null
   * @Return: null
   */
  calculateScrollPosition() {
    const _this: any = this;
    _this.$nextTick(() => {
      let a = setTimeout(() => {
        let oContent: any = document.querySelector("#content"); // 获取对象
        let oList: any = document.querySelector("#list"); // 获取对象
        if (_this.historyLoading) {
          oContent.scrollTop = oList.clientHeight - _this.boxScrollHeight;
          _this.historyLoading = false;
        } else {
          oContent.scrollTop = oList.clientHeight;
        }
        _this.boxScrollHeight = oList.clientHeight;
        _this.listShow = true;
        if (_this.listLoading && _this.uploadLength <= _this.uploadNum)
          _this.listLoading = false;
        if (_this.showLoading) _this.showLoading = false;
        clearTimeout(a);
      }, 20);
    });
  }
}
