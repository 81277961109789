const MineConfig = {
  account: {
    path: '/Account',
    name: 'Account',
    config:"MineConfig",
    key:'account',
    component: () => import('@/views/Mine/Account/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
      isRouter:"account"
    },
  },
  privacy: {
    path: '/Privacy',
    name: 'Privacy',
    config:"MineConfig",
    key:'privacy',
    component: () => import('@/views/Mine/Privacy/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  help: {
    path: '/Help',
    name: 'Help',
    config:"MineConfig",
    key:'help',
    component: () => import('@/views/Mine/Help/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  notice: {
    path: '/Notice',
    name: 'Notice',
    config:"MineConfig",
    key:'notice',
    component: () => import('@/views/Mine/Notice/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
      isRouter:"notice"
    },
  },
  collect: {
    path: '/Collect',
    name: 'Collect',
    config:"MineConfig",
    key:'collect',
    component: () => import('@/views/Mine/Collect/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
      isRouter:"collect"
    },
  },
  address: {
    path: '/Address',
    name: 'Address',
    config:"MineConfig",
    key:'address',
    component: () => import('@/views/Mine/Address/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
      isRouter:"address"
    },
  },
  order: {
    path: '/Order',
    name: 'Order',
    config:"MineConfig",
    key:'order',
    component: () => import('@/views/Mine/Order/List/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
      isRouter:"order"
    },
  },
  orderDetail: {
    path: '/OrderDetail',
    name: 'OrderDetail',
    config:"MineConfig",
    key:'orderDetail',
    component: () => import('@/views/Mine/Order/Details/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true
    },
  },
  coupon: {
    path: '/Coupon',
    name: 'Coupon',
    config:"MineConfig",
    key:'coupon',
    component: () => import('@/views/Mine/Coupon/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true,
      isRouter:"coupon"
    },
  },
  opinion: {
    path: '/Opinion',
    name: 'Opinion',
    config:"MineConfig",
    key:'opinion',
    component: () => import('@/views/Mine/Opinion/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:false
    },
  },
  orderComment: {
    path: '/OrderComment',
    name: 'OrderComment',
    config:"MineConfig",
    key:'orderComment',
    component: () => import('@/views/Mine/Order/Comment/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true
    },
  },
  orderLogistics: {
    path: '/OrderLogistics',
    name: 'OrderLogistics',
    config:"MineConfig",
    key:'orderLogistics',
    component: () => import('@/views/Mine/Order/Logistics/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true
    },
  },
  orderProcess: {
    path: '/OrderProcess',
    name: 'OrderProcess',
    config:"MineConfig",
    key:'orderProcess',
    component: () => import('@/views/Mine/Order/Process/Index.vue'),
    meta: {
      showTop: true,
      showSocket:true,
      showSearch:true,
      showFooter:true,
      showIcon:true,
      isToken:true
    },
  },
  weChatBind: {
    path: '/WeChatBind',
    name: 'WeChatBind',
    config:"MineConfig",
    key:'weChatBind',
    component: () => import('@/views/Mine/WeChatBind/Index.vue'),
    meta: {
      showTop: false,
      showSocket:false,
      showSearch:false,
      showFooter:false,
      isToken:false
    },
  },

};
export default MineConfig;
