var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',[(_vm.navType == 0)?_c('div',{staticClass:"header-box"},[_c('div',{staticClass:"header-box_nav"},[_c('ul',[_c('li',[_c('span',{on:{"click":function($event){return _vm.navChangen(0)}}},[_c('el-image',{attrs:{"src":require('@/assets/image/head/logo.png'),"fit":"fill"}})],1)]),_c('li',[_c('p',{staticClass:"title_1",on:{"click":function($event){return _vm.navChangen(1)}}},[_vm._v("储绣家居")])]),_c('li',[_c('p',{staticClass:"title_2",on:{"click":function($event){return _vm.navChangen(2)}}},[_vm._v("丝秘花园")])]),_c('li',[_c('p',{staticClass:"title_3",on:{"click":function($event){return _vm.navChangen(3)}}},[_vm._v("高级定制")])])]),_c('em',{directives:[{name:"show",rawName:"v-show",value:(_vm.navIndex != 0),expression:"navIndex != 0"}],style:({ left: _vm.iLeft + 'px' })})]),_c('div',{staticClass:"header-box_search",class:_vm.searchBoolean ? 'fadeInAnimate' : ''},[_c('div',{class:[
            { 'header-box_search__input': _vm.searchBoolean },
            { search_box: !_vm.searchBoolean },
            { fadeOutAnimate: _vm.searchFlag }
          ]},[(_vm.searchBoolean)?_c('i-input',{attrs:{"pla":"输入您想要搜索的产品","pHeight":"0","iHeight":"34","bg":"none","maxlength":10,"autofocus":_vm.searchBoolean},on:{"focusCallback":_vm.searchFocusCallback,"blurCallback":_vm.searchBlurCallback,"keyupCallback":_vm.searchFn},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('div',{staticClass:"header-box_search__input___div",on:{"click":function($event){return _vm.searchFn(!_vm.searchBoolean ? 'searchFlag' : '')}}},[_c('p',{staticClass:"text_px"},[_vm._v("搜索")]),_c('i',{staticClass:"el-icon-search"})])],1),_c('div',{staticClass:"header-box_search__content",style:({ height: _vm.searcHeight + 'px' })},[_c('ul',_vm._l((_vm.historySearchList),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.goSearch(item)}}},[_c('p',[_vm._v(_vm._s(item))]),_c('i',{staticClass:"custom-icon custom-icon-guanbi",on:{"click":function($event){$event.stopPropagation();return _vm.deleteHistorySearchFn(index)}}})])}),0)])]),_c('div',{staticClass:"header-box_shop",on:{"mouseover":_vm.shopShowFn,"mouseout":function($event){_vm.shopHeight = 0}}},[_c('div',{staticClass:"header-box_shop__div",on:{"click":function($event){return _vm.goIndex('shoppingCart')}}},[_c('p',[_vm._v("购物车("+_vm._s(_vm.shoppingCartListTotal)+")")]),_c('i',{staticClass:"custom-icon custom-icon-ShoppingCart"})]),_c('div',{staticClass:"header-box_shop__content",style:({ height: _vm.shopHeight + 'px' })},[(_vm.shoppingCartListTotal <= 0)?_c('div',{staticClass:"header-box_shop__content___no"},[_vm._v(" 购物车内还没有产品 ")]):[_c('div',{staticClass:"header-box_shop__content___item"},[_c('span',{class:{ active: _vm.shoppingCartList[0].commoditySeries == 2 }},[_c('el-image',{attrs:{"src":_vm.shoppingCartList[0].thumbnailUrl +
                      '?x-oss-process=image/resize,m_lfit,h_80,w_80'}})],1),(
                  (_vm.shoppingCartList[0].commoditySeries == 2 &&
                    _vm.shoppingCartList[0].disposalStatus == 1) ||
                    _vm.shoppingCartList[0].commoditySeries != 2
                )?[_c('p',{on:{"click":function($event){return _vm.goDetails(_vm.shoppingCartList[0].commodityId,_vm.shoppingCartList[0].commoditySeries)}}},[_vm._v(" "+_vm._s(_vm.shoppingCartList[0].commodityName)+" ")])]:[_c('p',[_vm._v(_vm._s(_vm.shoppingCartList[0].commodityName))])],(_vm.shoppingCartList[0].commoditySeries != 2)?_c('div',[_c('b',[_vm._v("￥"+_vm._s(_vm.$base.toFixed(_vm.shoppingCartList[0].cartPrice)))]),_c('i',{staticClass:"el-icon-close"}),_c('b',[_vm._v(_vm._s(_vm.shoppingCartList[0].cartNum))])]):_vm._e()],2),_c('div',{staticClass:"header-box_shop__content___btn"},[_c('p',[(_vm.shoppingCartList[0].isDeposit == 1)?[_vm._v("定金")]:_vm._e(),_vm._v("￥ "),((_vm.shoppingCartList[0].commoditySeries == 2 && _vm.shoppingCartList[0].disposalStatus == 1) || _vm.shoppingCartList[0].commoditySeries != 2 )?[_vm._v(" "+_vm._s(_vm.$base.toFixed(_vm.shoppingCartListPrice))+" ")]:[_vm._v("等待报价")]],2),_c('i-button',{attrs:{"iType":"primary","iRadius":"10","text":"去购物车结算","fontSize":"14","iHeight":"40","iWidth":"150px"},on:{"btnCallback":function($event){return _vm.goIndex('shoppingCart')}}})],1)]],2)]),(_vm.userInfo.accountId != -1)?[_c('div',{staticClass:"header-box_order",on:{"click":function($event){return _vm.goMine('order')}}},[_c('p',[_vm._v("我的定单")]),_c('i',{staticClass:"custom-icon custom-icon-order"})])]:_vm._e(),_c('div',{staticClass:"header-box_login"},[_c('div',{staticClass:"header-box_login__info"},[(_vm.userInfo.accountId == -1)?_c('div',{on:{"click":_vm.goLogin}},[_c('span',[_vm._v("登录/注册")]),_c('i',{staticClass:"custom-icon custom-icon-morentouxiang"})]):_c('div',{on:{"click":function($event){return _vm.goMine('account')}}},[_c('span',[_vm._v(_vm._s(_vm.userInfo.nickName))]),_c('b',[_c('el-image',{attrs:{"src":_vm.userInfo.avatar +
                    '?x-oss-process=image/resize,m_fill,h_26,w_26',"fit":"fill"}})],1)])]),(_vm.userInfo.accountId != -1)?_c('ul',[_c('li',{on:{"click":function($event){return _vm.goMine('account')}}},[_vm._v("我的账号")]),_c('li',{on:{"click":function($event){return _vm.goMine('collect')}}},[_vm._v("我的收藏")]),_c('li',{on:{"click":function($event){return _vm.goMine('address')}}},[_vm._v("我的地址")]),_c('li',{on:{"click":_vm.loginOutFn}},[_vm._v("退出登录")])]):_vm._e()])],2):(_vm.navType == 1)?_c('div',{staticClass:"pay"},[_c('div',{staticClass:"pay-logo"},[_c('span',{on:{"click":function($event){return _vm.navChangen(0)}}},[_c('el-image',{attrs:{"src":require('@/assets/image/head/logo.png'),"fit":"fill"}})],1)]),_c('div',{staticClass:"pay-order",on:{"click":function($event){return _vm.goMine('order')}}},[_c('p',[_vm._v("我的定单")]),_c('i',{staticClass:"custom-icon custom-icon-order"})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }