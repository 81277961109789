/**
 * @Author: HS
 * @Date: 2021/6/21 2:55 下午
 * @Description: 对称加密
 * @LastEditTime: 2021/6/21 2:55 下午
 * @LastEditors:
 * @FilePath: src/utils/encryption.ts
*/
const CryptoJS = require("crypto-js");
/**
 * @Author HS
 * @Date 2021/6/21 2:55 下午
 * @Description: 后台对称加密
 * @Params: { string : message - true } [message：加密字符]
 * @Return: null
*/
const adminKey = 'sanxiaxiu2020';
export const adminEncryptBy = (message:string) => {
    let afterEncrypt = CryptoJS.DES.encrypt(message, CryptoJS.enc.Utf8.parse(adminKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString()
    return afterEncrypt
}
/**
 * @Author HS
 * @Date 2021/6/21 2:55 下午
 * @Description: 后台对称解密
 * @Params: { string : afterEncrypt - true } [afterEncrypt：解密字符]
 * @Return: string
 */
export const adminDecryptBy = (afterEncrypt:string) => {
    let afterDecrypt = CryptoJS.DES.decrypt(afterEncrypt, CryptoJS.enc.Utf8.parse(adminKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return afterDecrypt
}

/**
 * @Author HS
 * @Date 2021/6/21 2:57 下午
 * @Description: 前端加密
 * @Params: { string : message - true } [message：加密字符]
 * @Return: null
*/
let key = 'sanxiaxiu20201119';
export const encryptBy = (message:string) => {
    function encryptByDES (message:string, key:string) {
        let keyHex = CryptoJS.enc.Utf8.parse(key)
        let option = {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7}
        let encrypted = CryptoJS.DES.encrypt(message, keyHex, option)
        return encrypted.ciphertext.toString()
    }
    return encryptByDES(message, key);
}

/**
 * @Author HS
 * @Date 2021/6/21 2:55 下午
 * @Description: 前端解密
 * @Params: { string : afterEncrypt - true } [afterEncrypt：解密字符]
 * @Return: string
 */
export const decryptBy = (message:string) => {
    //DES  ECB模式解密
    function decryptByDES(message:string,key:string){
        let keyHex = CryptoJS.enc.Utf8.parse(key);
        let decrypted = CryptoJS.DES.decrypt({
            ciphertext: CryptoJS.enc.Hex.parse(message)
        }, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        let result_value = decrypted.toString(CryptoJS.enc.Utf8);
        return result_value;
    }
    return decryptByDES(message, key);
}

/**
 * @Author HS
 * @Date 2021/6/21 2:55 下午
 * @Description: 获取存储数据，并进行解密
 * @Params: { string : name - true } [name：存储参数]
 * @Return: string
 */
export function getDecryptBy(name:string) {
    const data = localStorage.getItem(name)
    if (data == undefined || data == 'undefined' || data == null || data == 'null' || data == ""){
        return `${name}-暂未存储`
    }
    let str = decryptBy(data)
    // if(str.indexOf('{') > -1 || str.indexOf('[') > -1){
    //     return JSON.parse(str)
    // }
    return str
}


/**
 * @Author HS
 * @Date 2021/6/30 11:13 上午
 * @Description: 加密内容，可进行存储缓存
 * @Params: { string : data - true } [data：值]
 * @Params: { string : name - false } [name：名称]
 * @Return: string
*/
export function setEncryptBy(val:string,name:string) {
    let str = encryptBy(val)
    if(name == undefined || name == '' || name == null || name == 'null' ){
        return str
    }
    localStorage.setItem(name,str)
    return str
}
