import {WebSocketBox} from "@/interface/store/WebSocketBox";

const webSocket = {
    state: {
        socket:null,
        socketThis:null,
        isWebSocket:false,
		orderStatu:null
    },
    actions: {

    },
    mutations: {

        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: websocket对象存储
         */
        SET_WEBSOCKET(state:WebSocketBox, data:any) {
            state.socket = data.socket
            state.socketThis = data.socketThis
        },
        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 开启/关闭连接状态存储
         */
        SET_IS_WEBSOCKET(state:WebSocketBox, data:boolean) {
            state.isWebSocket = data
        },
        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: WebSocket断连
         */
        REMOVE_WEBSOCKET(state:WebSocketBox, data:any) {
            state.socket = null
            state.socketThis = null
        },
		ORDERSTATUS(state:any,data:any){
			state.orderStatu = data;//----订单信息
		}
    }
}
export default webSocket
