/**
 * @Author: HS
 * @Date: 2021/7/5 2:18 下午
 * @Description: 公共函数
 * @LastEditTime: 2021/7/5 2:18 下午
 * @LastEditors:
 * @FilePath: src/utils/base.ts
*/
import Router from "@/router/index"
import Store from "@/store/index"
import { setEncryptBy, encryptBy } from "@/utils/encryption"
export default {
    // toFixed 修复  num数值  s保留几位
    toFixed(num:number, s=2) {
        var times = Math.pow(10, s)
        var des:any = num * times + 0.5
        des = parseInt(des, 10) / times
        return des + ''
    },
    /**
     * @Author HS
     * @Date 2021/6/21 3:07 下午
     * @Description:
     * @Params: { any ： val - true } [val：值]
     * @Return: boolean
    */
    isNull(val:any) {
        if (val == undefined ||
            val == 'undefined' ||
            val == null ||
            val == 'null' ||
            val == "" ||
            val.length == 0 ||
            JSON.stringify(val) == "{}" ||
            val == false && (typeof val) != 'string'
        ) return true;
        else return false;
    },
    /**
     * @Author HS
     * @Date 2021/6/22 9:16 上午
     * @Description: 补充路径
     * @Params: { string ： src - true } [src：图片路径]
     * @Return: string
    */
    publicSrc(src:string) {
        return "https://sxxfile.oss-cn-zhangjiakou.aliyuncs.com/mobile" + src
    },
    /**
     * @Author HS
     * @Date 2021/6/22 9:29 上午
     * @Description:
     * @Params: { Array ： arr - true } [数组]
     * @Return:
    */
    unique(arr:any[]){
        var res = [];
        for (var i = 0; i < arr.length; i++) {
            res.lastIndexOf(arr[i]) !== -1 ? '' : res.push(arr[i]);
        }
        return res;
    },

    /**
     * @Author HS
     * @Date 2021/6/22 11:43 上午
     * @Description: json转数组
     * @Params: { JSON ： json - ture } [json：JSON]
     * @Params: { string ： key - ture } [key：键名]
     * @Params: { string ： value - ture } [value：键值]
     * @Return: arr
    */
    jsonSwitch(json:any, key:string, value:string) {
        let arr = []
        for (let attr in json) {
            const obj:any = {}
            obj[key] = attr
            obj[value] = json[attr]
            arr.push(obj)
        }
        return arr
    },
    /**
     * @Author HS
     * @Date 2021/6/24 2:59 下午
     * @Description: 判断是否登录
     * @Params: null
     * @Return: [boolean: true-未登录 false-已登录]
    */
    isLogin() {
        const data = localStorage.getItem("_TOKEN")
        console.log(data);
        let _flag:boolean = true
        this.isNull(data) ? _flag = true : _flag = false
        return _flag
    },

    /**
     * @Author HS
     * @Date 2021/6/24 3:08 下午
     * @Description: 判断页面是否重复
     * @Params: { string ： path - true } [path：路由]
     * @Return: boolean
    */
    repeatRouter(path:string){
        const currentRoute = Router.currentRoute.fullPath
        if(currentRoute === path){
            return true
        }
        return false
    },

    /**
     * @Author HS
     * @Date 2021/6/24 5:21 下午
     * @Description: 当前设备是不是移动端
     * @Params: null
     * @Return: boolean
    */
    isMobile() {
        if (!window.navigator) {
            console.log("移动端");
            return true
        } else {
            if (/Mobile|Android|webOS|iPhone|iPad|Phone/i.test(navigator.userAgent)) {
                console.log("移动端");
                return true
            } else {
                console.log("pc端");
                return false
            }
        }
    },
    /**
     * @Author HS
     * @Date 2021/6/24 5:39 下午
     * @Description: json序列化
     * @Params: { JSON ： json - ture } [json：JSON]
     * @Return: string
    */
    serializeStr(json:any) {
        let arr = []
        for (let i in json) {
            arr.push(i + "=" + json[i]);
        }
        let str = arr.join('&')
        return str
    },
    /**
     * @Author HS
     * @Date 2021/6/24 5:39 下午
     * @Description: json序列化 * - 区分
     * @Params: { JSON ： json - ture } [json：JSON]
     * @Return: string
     */
    serializeStrTow(json:any) {
        let arr = []
        for (let i in json) {
            arr.push(i + "-" + json[i]);
        }
        let str = arr.join('*')
        return str
    },
    /**
     * @Author HS
     * @Date 2021/6/24 5:39 下午
     * @Description: 字符串转json
     * @Params: { string ： str - ture } [str]
     * @Params: { string ： symbolOne - false } [符号1]
     * @Params: { string ： symbolTwo - false } [符号2]
     * @Return: object
     */
    serializeJson(str:string,symbolOne:string = '&',symbolTwo:string = '=') {
        let arr = str.split(symbolOne)
        let obj:any = {}
        arr.map((item:any) => {
            let data = item.split(symbolTwo)
            obj[data[0]] = data[1]
        })
        return obj
    },
    /**
     * @Author HS
     * @Date 2021/6/24 5:57 下午
     * @Description: 时间戳转时间格式
     * @Params: { number ： iDate - true } [iDate：时间戳]
     * @Params: { number ： iType - false } [iType：默认-年月日时分秒 | 1-年月日 | 2-年月日时分]
     * @Return: string
    */
    formatDate(iDate:number,iType:number) {
        let date = new Date(iDate);
        let YY = date.getFullYear() + '-';
        let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        if(iType == 1){
            return YY + MM + DD;
        }
        if(iType == 2){
            return YY + MM + DD + " " + hh + mm;
        }
        return YY + MM + DD + " " + hh + mm + ss;
    },
    /**
     * @Author HS
     * @Date 2021/6/24 6:08 下午
     * @Description: 时间格式转时间戳
     * @Params: { string ： strtime - true } [strtime：]
     * @Return: number
    */
    timestamp(strtime:string){
        let dateStr = new Date(strtime.replace(/-/g, '/'));
        return dateStr.getTime();
    },
    /**
     * @Author HS
     * @Date 2021/6/29 4:03 下午
     * @Description: 页面跳转
     * @Params: { string ： path - true } [path：路由]
     * @Return:
    */
    goRouter(path:string){
        const currentRoute = Router.currentRoute.fullPath
        if(currentRoute === path){
            Router.go(0)
            return true
        }
        Router.push(path)
        return false
    },

    /**
     * @Author HS
     * @Date 2021/6/30 5:00 下午
     * @Description: 退出登录
     * @Params: null
     * @Return: null
    */
    quitLogin(){
        localStorage.removeItem('_USERINFO')
        localStorage.removeItem('_TOKEN')
        Store.commit('REMOVE_USETINFO');
        Store.commit('REMOVE_TOKEN');
        Store.commit('REMOVE_LIST');
        Store.commit('REMOVE_ADDRESS_LIST');
        const currentRoute = Router.currentRoute.fullPath
        const jumpRouter = ['/']
        console.log(currentRoute,'^^^^^^^^^^^^^^^^^^^^')
        console.log(jumpRouter.indexOf(currentRoute),'^^^^^^^^^^^^^^^^^^^^')
        if(jumpRouter.indexOf(currentRoute) == -1){
            Router.push('/')
        }
    },

    /**
     * @Author HS
     * @Date 2021/7/6 9:29 上午
     * @Description: 设置用户信息
     * @Params: { object ： info - true } [需修改的用户信息JSON]
     * @Return: null
    */
    setUserInfo(info:object){
        let sUserInfo = Store.getters.userInfo
        const userInfo = Object.assign({},sUserInfo,info)
        Store.commit('SET_USETINFO',JSON.stringify(userInfo))
        setEncryptBy(JSON.stringify(userInfo),"_USERINFO")
    },

    /**
     * @Author HS
     * @Date 2021/7/8 10:40 上午
     * @Description: 路由拼接
     * @Params: { string ： path - true } [需跳转路由]
     * @Params: { string ： r - false } [回调参数]
     * @Return: string
    */
    routeLinkSplice(path:string,r:string){
        if(this.isNull(r))return path

        if(r.indexOf('=') == -1){
            return path + "?r=" + encryptBy(r)
        }
        let arr = r.split('&')
        let str = '';
        arr.map((item:string,index:number) => {
            let arrToo = item.split('=')
            let s = arrToo[0] + "=" + encryptBy(arrToo[1])
            if(index == arr.length - 1){
                str += s
            }
            else{
                str += s + "&"
            }
        })
        return path + "?" + str
    },
    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 乘法
     * @Params: { number ： arg1 - true } [数字1]
     * @Params: { number ： arg1 - true } [数字2]
     * @Return: number
    */
    accMul (arg1:number, arg2:number) {
        let m=0,s1=arg1.toString(),s2=arg2.toString();
        try{m+=s1.split(".")[1].length}catch(e){}
        try{m+=s2.split(".")[1].length}catch(e){}
        return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m)
    },
    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 除法
     * @Params: { number ： arg1 - true } [数字1]
     * @Params: { number ： arg1 - true } [数字2]
     * @Return: number
     */
    accDiv (arg1:number, arg2:number) {
        let t1 = 0, t2 = 0, r1, r2;
        try {t1 = arg1.toString().split(".")[1].length} catch (e) {}
        try {t2 = arg2.toString().split(".")[1].length} catch (e) {}
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        return this.accMul((r1 / r2), Math.pow(10, t2 - t1));
    },
    // 加法（解决精度丢失）--- Author 陈
	accAddC(arg1:number, arg2:number) {
		var r1, r2, m;
		try {
			r1 = arg1.toString().split(".")[1].length
		} catch (e) {
			r1 = 0
		}
		try {
			r2 = arg2.toString().split(".")[1].length
		} catch (e) {
			r2 = 0
		}
		m = Math.pow(10, Math.max(r1, r2))
		return (arg1 * m + arg2 * m) / m
	},
    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 加法
     * @Params: { number ： arg1 - true } [数字1]
     * @Params: { number ： arg1 - true } [数字2]
     * @Return: number
     */
    accAdd (arg1:number, arg2:number) {
        let r1,r2,m;
        try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
        try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
        m=Math.pow(10,Math.max(r1,r2))
        return (arg1*m+arg2*m)/m
    },
    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 减法
     * @Params: { number ： arg1 - true } [数字1]
     * @Params: { number ： arg1 - true } [数字2]
     * @Return: number
     */
    subtr (arg1:number, arg2:number) {
        let r1,r2,m,n;
        try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
        try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
        m=Math.pow(10,Math.max(r1,r2));
        n=(r1>=r2)?r1:r2;
        return ((arg1*m-arg2*m)/m).toFixed(n);
    },

    /**
     * @Author HS
     * @Date 2021/8/6 2:31 下午
     * @Description: 时间戳转倒计时
     * @Params: { number ： endTimeStamp - true } [时间戳]
     * @Return: object
    */
    countDown(endTimeStamp:number) {
        let nowTimeStamp = new Date().getTime()
        let time = {}
        if (endTimeStamp > nowTimeStamp) {
            let mss = endTimeStamp - nowTimeStamp;
            let days = Math.floor(mss / (1000 * 60 * 60 * 24))
            let hours = Math.floor((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            let minutes = Math.floor((mss % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = Math.floor((mss % (1000 * 60)) / 1000)
            time = {
                day: days < 10 ? "0" + days : days,
                hour: hours < 10 ? "0" + hours : hours,
                minute: minutes < 10 ? "0" + minutes : minutes,
                second: seconds < 10 ? "0" + seconds : seconds,
                mss: mss,
                end:false
            }
        } else {
            time = {
                day: '00',
                hour: '00',
                minute: '00',
                second: '00',
                mss: '00',
                end:true
            }
        }
        return time
    }


}
