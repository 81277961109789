// import request from "../request"
import request from "../http"
import {
    AddAddressReq,
    AddFeedbackReq,
    ChangeInfoReq,
    ChangePasswordReq,
    ChangePhoneReq,
    DeleteAddressReq,
    DeleteCollectionReq,
    GetByPageEvaluateReq,
    GetByPageOrderReq,
    GetMyCollectionReq,
    GetOrderProcessReq,
    ModifyAddressReq,
    NewPhoneReq, OauthBindConfirmReq,
    OrderIdReq,
    SubmitEvaluateReq, UnboundReq,
    VerificationCodeReq
} from '@/interface/res/mine'
import {GetPreTimeByCartReq} from "@/interface/res/common";
//修改用户信息
export function ChangeInfoApi (params:ChangeInfoReq){
    return request({
        url: '/shop/account/info/changeInfo',
        method: 'post',
        params,
        isToken:true
    })
}
//获取修改密码验证码
export function SmsChangePasswordApi (params:{} = {}){
    return request({
        url: '/shop/account/sms/changePassword',
        method: 'get',
        params,
        isToken:true
    })
}
//判断修改密码验证码
export function SmsValidationChangePasswordApi (params:VerificationCodeReq){
    return request({
        url: '/shop/account/sms/validationChangePassword',
        method: 'get',
        params,
        isToken:true
    })
}
//修改密码
export function ChangePasswordApi (params:ChangePasswordReq){
    return request({
        url: '/shop/account/info/changePassword',
        method: 'post',
        params,
        isToken:true
    })
}
//获取修改手机号验证码
export function SmsChangePhoneApi (params:{} = {}){
    return request({
        url: '/shop/account/sms/changePhone',
        method: 'get',
        params,
        isToken:true
    })
}
//判断修改手机验证码
export function SmsValidationChangePhoneApi (params:VerificationCodeReq){
    return request({
        url: '/shop/account/sms/validationChangePhone',
        method: 'get',
        params,
        isToken:true
    })
}
//获取新的安全手机号码验证码
export function SmsNewPhoneApi (params:NewPhoneReq){
    return request({
        url: '/shop/account/sms/newPhone',
        method: 'get',
        params,
        isToken:true
    })
}
//修改安全手机
export function ChangePhoneApi (params:ChangePhoneReq){
    return request({
        url: '/shop/account/info/changePhone',
        method: 'post',
        params,
        isToken:true
    })
}
//获取我的收藏
export function GetMyCollectionApi (params:GetMyCollectionReq){
    return request({
        url: '/shop/account/collection/getMyCollection',
        method: 'get',
        params,
        isToken:true
    })
}
//删除我的收藏
export function DeleteCollectionApi (params:DeleteCollectionReq){
    return request({
        url: '/shop/account/collection/deleteCollection',
        method: 'post',
        params,
        isToken:true
    })
}
//我的地址
export function GetMyAddressApi (params:{} = {}){
    return request({
        url: '/shop/account/address/getMyAddress',
        method: 'get',
        params,
        isToken:true
    })
}
//添加地址
export function AddAddressApi (params:AddAddressReq){
    return request({
        url: '/shop/account/address/addAddress',
        method: 'post',
        params,
        isToken:true
    })
}
//删除地址
export function DeleteAddressApi (params:DeleteAddressReq){
    return request({
        url: '/shop/account/address/deleteAddress',
        method: 'post',
        params,
        isToken:true
    })
}
//修改地址
export function UpdateAddressApi (params:AddAddressReq){
    return request({
        url: '/shop/account/address/updateAddress',
        method: 'post',
        params,
        isToken:true
    })
}
//查询定单列表
export function GetByPageOrderApi (params:GetByPageOrderReq){
    return request({
        url: '/shop/order/getByPage',
        method: 'get',
        params,
        isToken:true
    })
}
//查询定单详情
export function GetByIdOrderApi (params:OrderIdReq){
    return request({
        url: '/shop/order/getById',
        method: 'get',
        params,
        isToken:true
    })
}
//删除定单
export function DeleteOrderApi (params:OrderIdReq){
    return request({
        url: '/shop/order/deleteOrder',
        method: 'post',
        params,
        isToken:true
    })
}
//退款
export function RefundOrderApi (params:OrderIdReq){
    return request({
        url: '/shop/order/refund',
        method: 'post',
        params,
        isToken:true
    })
}
//取消定单
export function CancelOrderApi (params:OrderIdReq){
    return request({
        url: '/shop/order/cancelOrder',
        method: 'post',
        params,
        isToken:true
    })
}
//接受延时
export function AcceptOrderApi (params:OrderIdReq){
    return request({
        url: '/shop/order/acceptOrder',
        method: 'post',
        params,
        isToken:true
    })
}
//确认收货
export function ConfirmReceiptApi (params:OrderIdReq){
    return request({
        url: '/shop/order/confirmReceipt',
        method: 'post',
        params,
        isToken:true
    })
}
//修改地址
export function ModifyAddressApi (params:ModifyAddressReq){
    return request({
        url: '/shop/order/modifyAddress',
        method: 'post',
        params,
        isToken:true
    })
}

// 加入购物车实时获取预排单信息
export function GetPreTimeByCartApi (params:GetPreTimeByCartReq){
    return request({
        url: '/shop/scheduling/getPreTimeByCart',
        method: 'get',
        params,
        isToken:true
    })
}
// 
export function GetPaymentByZero (params:OrderIdReq){
    return request({
        url: '/shop/order/paymentByZero',
        method: 'post',
        params,
        isToken:true
    })
}
// 提交定单获取预排单信息
export function GetPreTimeByOrderIdApi (params:OrderIdReq){
    return request({
        url: '/shop/scheduling/getPreTimeByOrderId',
        method: 'get',
        params,
        isToken:true
    })
}

// 查询物流
export function GetLogisticsApi (params:OrderIdReq){
    return request({
        url: '/shop/order/getLogistics',
        method: 'get',
        params,
        isToken:true
    })
}

// 评论列表
export function GetByPageEvaluateApi (params:GetByPageEvaluateReq){
    return request({
        url: '/shop/evaluate/getByPage',
        method: 'get',
        params,
        isToken:true
    })
}

// 查询定单产品流程
export function GetOrderProcessApi (params:GetOrderProcessReq){
    return request({
        url: '/shop/order/getOrderProcess',
        method: 'get',
        params,
        isToken:true
    })
}

// 查询定单产品详情
export function GetOrderDetailByIdApi (params:GetOrderProcessReq){
    return request({
        url: '/shop/order/getOrderDetailById',
        method: 'get',
        params,
        isToken:true
    })
}

// 定单评论
export function SubmitEvaluateApi (params:SubmitEvaluateReq){
    return request({
        url: '/shop/evaluate/submitEvaluate',
        method: 'post',
        params,
        isToken:true
    })
}

// 反馈意见
export function AddFeedbackApi (params:AddFeedbackReq){
    return request({
        url: '/shop/feedback/addFeedback',
        method: 'post',
        params,
        isToken:true
    })
}

// 微信解绑
export function UnboundApi (params:UnboundReq){
    return request({
        url: '/shop/account/oauth/unbound',
        method: 'post',
        params,
        isToken:true
    })
}

// 账号确认绑定第三方
export function OauthBindConfirmApi (params:OauthBindConfirmReq){
    return request({
        url: '/shop/account/oauth/bind/confirm',
        method: 'post',
        params,
        isToken:true
    })
}



