const LoginConfig = {
  login: {
    path: '/Login',
    name: 'Login',
    config:"LoginConfig",
    key:'login',
    component: () => import('@/views/Login/Login/Index.vue'),
    meta: {
      showTop: false,
      showSocket:false,
      showSearch:false,
      showFooter:false,
      isToken:false
    },
  },
  code: {
    path: '/Code',
    name: 'Code',
    config:"LoginConfig",
    key:'code',
    component: () => import('@/views/Login/Code/Index.vue'),
    meta: {
      showTop: false,
      showSocket:false,
      showSearch:false,
      showFooter:false,
      isToken:false
    },
  },
  register: {
    path: '/Register',
    name: 'Register',
    config:"LoginConfig",
    key:'register',
    component: () => import('@/views/Login/Register/Index.vue'),
    meta: {
      showTop: false,
      showSocket:false,
      showSearch:false,
      showFooter:false,
      isToken:false
    },
  },
  forgotPassword: {
    path: '/ForgotPassword',
    name: 'ForgotPassword',
    config:"LoginConfig",
    key:'forgotPassword',
    component: () => import('@/views/Login/ForgotPassword/Index.vue'),
    meta: {
      showTop: false,
      showSocket:false,
      showSearch:false,
      showFooter:false,
      isToken:false
    },
  },
  weChatLogin: {
    path: '/WeChatLogin',
    name: 'WeChatLogin',
    config:"LoginConfig",
    key:'weChatLogin',
    component: () => import('@/views/Login/WeChatLogin/Index.vue'),
    meta: {
      showTop: false,
      showSocket:false,
      showSearch:false,
      showFooter:false,
      isToken:false
    },
  },
};
export default LoginConfig;
