import {ServiceFace} from "@/interface/store/service";
const webSocket = {
    state: {
        show:false,
        unread:0,
        message:{},
        isHistory:false,
        list:[],
        customizeInfo:""
    },
    actions: {

    },
    mutations: {
        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 客服弹窗打开
        */
        SET_SERVICESHOW(state:ServiceFace, data:boolean) {
            state.show = data
        },
        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 未读消息条数
         */
        SET_UNREAD(state:ServiceFace, data:number) {
            state.unread = data
        },
        /**
         * @Author HS
         * @Date 2021/7/28 5:32 下午
         * @Description: 是否有历史记录
        */
        SET_ISHISTORY(state:ServiceFace, data:boolean) {
            state.isHistory = data
        },
        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 消息内容
         */
        SET_MESSAGE(state:ServiceFace, data:any) {
            state.message = data
        },
        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 消息列表
         */
        SET_MESSAGE_LIST(state:ServiceFace, data:Array<any>) {
            state.list = data
        },
        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 新消息内容-添加
         */
        SET_MESSAGE_LIST_PUSH(state:ServiceFace, data:any) {
            state.list.push(data)
        },
        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 历史消息内容-添加
         */
        SET_MESSAGE_LIST_UNSHIFT(state:ServiceFace, data:any) {
            state.list = [...data,...state.list]
        },

        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 高级定制产品发起确认
         */
        SET_CUSTOMIZEINFO(state:ServiceFace, data:string) {
            state.customizeInfo = data
        },

        /**
         * @Author HS
         * @Date 2021/7/27 2:10 下午
         * @Description: 高级定制产品发起确认-删除
         */
        REMOVE_CUSTOMIZEINFO(state:ServiceFace) {
            state.customizeInfo = ""
        },




    }
}
export default webSocket
